// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
//require("@rails/activestorage").start()
//require("channels")

import '../css/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import fontawesome from '@fortawesome/fontawesome'
import faSolid from '@fortawesome/fontawesome-free-solid'
import faBrands from '@fortawesome/fontawesome-free-brands'
fontawesome.library.add(faSolid)
fontawesome.library.add(faBrands)

document.addEventListener('DOMContentLoaded', function () {
  // Functions
  function getAll(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
  }

  // Modals
  var rootEl = document.documentElement;
  var $modals = getAll('.modal');
  var $modalButtons = getAll('.js-modal-button');
  var $modalCloses = getAll('.modal-background, .modal-close');

  if ($modalButtons.length > 0) {
    $modalButtons.forEach(function ($el) {
      $el.addEventListener('click', function () {
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        rootEl.classList.add('is-clipped');
        $target.classList.add('is-active');
      });
    });
  }

  if ($modalCloses.length > 0) {
    $modalCloses.forEach(function ($el) {
      $el.addEventListener('click', function () {
        closeModals();
      });
    });
  }

  function closeModals() {
    rootEl.classList.remove('is-clipped');
    $modals.forEach(function ($el) {
      $el.classList.remove('is-active');
    });
  }

  // header scroll
  var $scrollFixHeader = document.getElementById("js-scroll-fix-header");
  if ($scrollFixHeader) {
    function fixHeader() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 200) {
        $scrollFixHeader.classList.add('is-active')
      } else {
        $scrollFixHeader.classList.remove('is-active')
      }
    }
    window.addEventListener('scroll', fixHeader);
  }

  // 目次
  var $toggleHeadingListButton = document.getElementById('js-toggle-heading-list-button');
  if ($toggleHeadingListButton) {
    $toggleHeadingListButton.addEventListener('click', function () {
      var $target = document.getElementById($toggleHeadingListButton.dataset.target);
      if ($target.classList.contains('blur')) {
        $toggleHeadingListButton.innerHTML = '閉じる'
      } else {
        $toggleHeadingListButton.innerHTML = '全ての目次を見る'
      }
      $target.classList.toggle('blur');
    });
  }

  var easing = function (t, b, c, d) { return c * (0.5 - Math.cos(t / d * Math.PI) / 2) + b; }; //jswing
  var $headingListLinks = Array.prototype.slice.call(document.querySelectorAll('.js-heading-list-link'), 0);
  if ($headingListLinks.length > 0) {
    // Add a click event on each of them
    $headingListLinks.forEach(function ($el) {
      $el.addEventListener('click', function () {
        // Get the target from the "data-target" attribute
        var target = $el.dataset.target;
        var $target = document.getElementById(target);
        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        var targetPos = $target.getBoundingClientRect().top - 70; // 70 : header height

        var startTime = Date.now();
        var scrollFrom = window.pageYOffset || document.documentElement.scrollTop;
        (function loop() {
          var currentTime = Date.now() - startTime;
          if(currentTime < 500) {
            scrollTo(0, easing(currentTime, scrollFrom, targetPos, 500));
            window.requestAnimationFrame(loop);
          } else {
            scrollTo(0, targetPos + scrollFrom);
          }
        })();
      });
    });
  }

  var $headingList = document.getElementById("heading_list");
  var $sidebarHeadingList = document.getElementById("sidebar_heading_list");
  var headingInfoList = []
  var $headingElements = Array.prototype.slice.call(document.querySelectorAll('.js-heading'), 0);
  $headingElements.forEach(function ($el) {
    var elementScrollTop = (document.documentElement.scrollTop || document.body.scrollTop) + $el.getBoundingClientRect().top
  })
  headingInfoList.reverse()

  if ($headingList && $sidebarHeadingList && $headingElements) {
    var boundaryX = (document.documentElement.scrollTop || document.body.scrollTop) + $headingList.getBoundingClientRect().bottom - 1;
    window.addEventListener("scroll", function() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > boundaryX) {
        $sidebarHeadingList.classList.remove("hidden");
      } else {
        $sidebarHeadingList.classList.add("hidden");
      }
      var no_active = true
      headingInfoList.forEach(function(info) {
        if (no_active && scrollTop >= info.top) {
          info.mokujiElement.classList.add('is-active')
          no_active = false
        } else {
          info.mokujiElement.classList.remove('is-active')
        }
      });
    });
  }
}());
